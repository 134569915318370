import React from "react"
import "../components/layout.scss"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Form,
  ResponsiveEmbed,
  Alert,
  ListGroup,
} from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import SimpleContactForm from "../components/simpleContactForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarAlt,
  faMoneyBillWave,
  faBalanceScaleLeft,
  faSmile,
  faSmileBeam,
  faCheckCircle,
  faCheck,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons"
import RequestCaseEvaluationCTA from "../components/requestCaseEvaluationCTA"
import { dom, config } from "@fortawesome/fontawesome-svg-core"

// Disable the automatic insertion
// of CSS into the head of the document.
config.autoAddCss = false

export default ({ data, location }) => (
  <section>
    {/* {JSON.stringify(location)} */}
    <Helmet
      title="Criminal Defense - Russo, White &amp; Keller, P.C."
      defer={false}
    >
      <style type="text/css">{dom.css()}</style>
    </Helmet>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(0,0,0,.85) 0%, rgba(0,0,0,.85) 100%)`,
        data.cover.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    >
      <Navbar
        bg="light"
        expand="lg"
        className="shadow-sm text-dark"
        style={{ minHeight: "10vh" }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="mx-auto mx-sm-0">
            Russo, White &amp; Keller
            <span className="d-none d-sm-inline-block">, P.C.</span>
          </Navbar.Brand>

          <section className="mx-auto mr-xl-0 ml-xl-auto text-center pt-2 position-relative">
            <h3>
              <a href="tel://+12058332589" className="stretched-link">
                (205) 833-2589
              </a>
            </h3>
            <p className="text-uppercase mb-0 font-weight-bold">
              Free Case Evaluation
            </p>
          </section>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="text-center text-uppercase font-weight-bolder">
          <Col
            className="px-0 px-3 py-2 bg-primary text-light"
            style={{ letterSpacing: "4px" }}
          >
            Being accused of a crime is traumatic. We will defend you.
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="text-light align-items-center align-items-md-start justify-content-center">
          <Col xs="12">
            <h1 className="text-light text-center pt-4 pb-2 pb-lg-4 text-shadow-dark">
              Criminal Defense Attorney
              <br /> in Birmingham
            </h1>
          </Col>

          <Col
            className="h-100"
            sm={{ span: 10, offset: 1 }}
            md={{ span: 6, offset: 0 }}
            xl="6"
          >
            <p className="lead">
              Don't let a criminal charge ruin your life. Start with a free case
              evaluation, you have nothing to lose and everything to gain.
            </p>

            <ListGroup variant="flush" className="pb-3">
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />{" "}
                <span className="ml-4">
                  Case evaluations are completely free.
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />{" "}
                <span className="ml-4">
                  We will vigorously defend you, and will not back down.
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />{" "}
                <span className="ml-4">
                  We recommend strategies specific to your circumstances.
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col
            xs="12"
            md="6"
            xl="5"
            className="px-5 py-5"
            style={{
              background:
                "linear-gradient(180deg, rgba(153,44,1,1) 0%, rgba(153,44,1,.2) 100%)",
            }}
          >
            <h4 className="text-light text-shadow-dark text-uppercase">
              Request Case Evaluation
            </h4>
            <p>
              Get your case reviewed by an experienced attorney. Get started
              now.
            </p>
            <SimpleContactForm type="criminal defense" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
    <Container fluid className="text-center bg-primary text-light">
      <Container>
        <Row>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-2x" />
              <div className="ml-2">Flexible Appointments</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-0 py-sm-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faMoneyBillWave} className="fa-2x" />
              <div className="ml-2">Affordable Services</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faBalanceScaleLeft} className="fa-2x" />
              <div className="ml-2">Experienced Legal Team</div>
            </section>
          </Col>
        </Row>
      </Container>
    </Container>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.9) 100%)`,
        data.cover2.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    >
      <Container className="text-center text-dark py-xl-5">
        <Row
          className="align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <Col xl="8" className="text-center mb-5">
            <h2 className="mt-5 mb-3 mt-md-3">
              Experienced Criminal Defense Representation
            </h2>
            <p>
              When you have been charged with a criminal offense, your future is
              at stake. You need an experienced criminal defense attorney who
              will treat your case with compassion and dedication to protect
              your rights and freedom. You'll get that here at Russo, White
              &amp; Keller, P.C.
            </p>
            <p>
              For over 20 years, we have been a passionate defender of clients
              charged with a variety of criminal crimes. Criminal cases are
              often highly technical in nature. To avoid a conviction, you need
              an attorney that is deeply knowledgeable in criminal trials and
              has the courtroom experience to mount a convincing defense for
              you.
            </p>
            <p>
              If you have been charged with one of the following offenses, we
              can help:
            </p>

            <Row className="my-4">
              {/* todo: add d-flex, center items, set border color, add more offenses */}
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Drunk Driving (DUI/DWI)</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Driving Offenses</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Probation</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>assault</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Drug Crimes</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Sex Crimes</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>trafficking</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Violent Crimes</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Domestic Violence</div>
              </Col>
            </Row>

            {/* <li>murder</li>
            <li>assault</li>
            <li>sex-related crimes</li>
            <li>failure to register</li>
            <li>theft</li>
            <li>robbery</li>
            <li>drug offenses</li>
            <li></li>
            <li>DUI/BUI/WUI</li> */}
            <RequestCaseEvaluationCTA type="criminal defense" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  </section>
)
export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cover2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover2: file(relativePath: { eq: "cover5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
